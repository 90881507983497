<template>
  <div class="input-number-container" :class="{ 'override-flex-end': shouldBeFlexEnd }">
    <div class="input-number-minus">
      <span class="inputNumber__arrow" @click="decreaseValue">
        <i class="fa-solid fa-circle-minus fa-xl"></i>
      </span>
    </div>

    <div class="input-number">
      <input
          type="number"
          class="input"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>

    <div class="input-number-plus">
      <span class="inputNumber__arrow" @click="increaseValue">
        <i class="fa-solid fa-circle-plus fa-xl"></i>
      </span>
    </div>

  </div>

</template>

<script>
export default {
  name: "InputNumber",
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Number],
    },
    shouldBeFlexEnd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputValue: 0,
    };
  },
  methods: {
    decreaseValue() {
      const newValue = this.modelValue === 0 ? 0 : this.modelValue - 1
      this.$emit('update:modelValue', newValue)
    },
    increaseValue() {
      this.$emit('update:modelValue', this.modelValue + 1)
    },
  },
};
</script>

<style lang="scss" scoped>
.input-number-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.override-flex-end {
  justify-content: flex-end;
}

.input-number-plus,
.input-number-minus {
  display: block;
  cursor: pointer;
}

.input-number {
  display: block;
  margin-left: 12px;
  margin-right: 12px;
  @media screen and (max-width: $md) {
    margin-left: 8px;
    margin-right: 8px;
  }

  input {
    text-align: center;
    height: 50px;
    width: 75px;
    padding: 12px 15px 14px 15px;
    font-size: 18px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #28677d;
    border-radius: 3px;
    outline: none;
    color: #707070;

    @media screen and (max-width: 790px) {
      width: 100%;
      height: 45px;
    }
  }

  /* Hide Up/Down Arrows in Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide Up/Down Arrows in Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>